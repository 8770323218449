<template>
    <thead>
        <tr class="r-hideInMobile">
            <th  v-for="(column, index) in columns" :key="`${customKey}-${index}`"
                :class="request.sortBy == column.fieldName ? `r-isSorting ${request.sorting}` : ''" 
                > 
                <div class="r-isClickAble r-center-flex" @click.prevent="setSortBy(column.fieldName)" v-if="column.isSortable" > 
                    <span class="r-mr-auto">{{column.label}} </span> 
                    <img :src="require(`@/assets/icons/others/sortBy-arrow-${request.sortBy == column.fieldName ? 'blue' : 'gray'}.svg`)"> 
                </div>
                <span v-else>{{column.label}}</span>
            </th>
        </tr>
    </thead>
</template>
<script>
import tableMethods from '@/mixins/tableMethods'
export default {
    mixins: [tableMethods],
    props: {
        columns: {
            require: true
        },
        request: {
            require: true
        },
        customKey: {
            default: 'list'
        },
    },
    methods: {
        setSortBy(value){
            this.$emit("setSortBy", value)
        }
    }
}
</script>