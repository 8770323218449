<template>
    <td :data-label="label" :class="className" > <slot></slot> </td>
</template>
<script>
export default {
    props: {
        label: {
            default: null
        },
        className: {
            default: null
        },
        fieldName: {
            require: true
        },
        item: {
            require: true
        }
    }
}
</script>