<template>
    <div class="r-place r-mtb-10  r-ptb-8 r-sortingData">
        <div class="r-autocomplete2"  @click.prevent="sortAbleDropdown = !sortAbleDropdown">
            <div class="r-inputPlace r-plr-16  r-isClickAble">
                <div class="r-center-flex y-hasIcon-right y-lr-size" :class="request.sorting">
                    <input type="text" :value="getColumnData(request.sortBy)" readonly class="r-isExpand">
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require(`@/assets/icons/others/sortBy-arrow-blue.svg`)" style="width: 12px"> 
                    </div>
                </div>
            </div>
            <div class="r-autocomplete2-place" v-if="sortAbleDropdown" @mouseleave="sortAbleDropdown = false">
                <div class="r-autocomplete2-items">
                        <div class="r-autocomplete2-item" v-for="(l, index) in sortAbleItem" :key="`${customKey}-${index}`" @click.prevent="setSortBy(l.fieldName)" >{{l.label}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import tableMethods from '@/mixins/tableMethods'

export default {
    mixins: [tableMethods],
    props: {
        columns: {
            require: true
        },
        request: {
            require: true
        },
        customKey: {
            require: true
        }
    },
    computed: {
        sortAbleItem(){
            return this.columns.filter(x => x.isSortable)
        }
    },
    data: () => ({
        sortAbleDropdown: false
    }),
    methods: {
        setSortBy(value){
            this.$emit("setSortBy", value)
        }
    }
}
</script>