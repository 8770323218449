<template>
    <div>
        <div class="r-table">
            <table>
                <thead>
                    <tr class="hideThead" :class="showThead ? 'showThead' : ''">
                        <th class="r-table-firstColumn">No</th>
                        <th class="r-table-unsortable">No Faktur</th>
                        <th class="r-table-unsortable">Username</th>
                        <th class="r-table-unsortable">Site</th>
                        <th class="r-table-unsortable">Game</th>
                        <th class="r-table-unsortable">Nomor</th>
                        <th class="r-table-unsortable">Beli</th>
                        <th class="r-table-unsortable">Bayar</th>
                        <th class="r-table-unsortable">Menang</th>
                    </tr>
                </thead>
                <loading v-if="fetchData"/>
                <nodata v-if="data.length == 0"></nodata>
                <tr v-for="(item, index) in data" :key="`${index}-periode`">
                    <td data-label="No" class="r-table-firstColumn" >{{curNumber(index)}}</td>
                    <td data-label="No Faktur" >{{item.noFaktur}}</td>
                    <td data-label="Username" >{{item.username}} </td>
                    <td data-label="Site" >{{item.site}} </td>
                    <td data-label="Game" >{{item.game}} </td>
                    <td data-label="Nomor" >
                        <div class="r-grid-items-2">
                            <input type="text" v-model="item.nomor">
                            <button class="y-purple-outline y-purple-text y-white-bg y-md-size" 
                                @click.prevent.stop="updateData(item)"
                            >
                                Update
                            </button>
                        </div>
                    </td>
                    <td data-label="Beli" >{{price(item.beli)}} </td>
                    <td data-label="Bayar" >{{price(item.bayar)}} </td>
                    <td data-label="Menang" >{{price(item.menang)}} </td>
                </tr>
            </table>
        </div>
        <div class="r-table">
            <tr>
                <td>
                    Total Beli
                </td>
                <td>
                    {{price(totalBeli)}} 
                </td>
            </tr>
            <tr>
                <td>
                    Total Bayar
                </td>
                <td>
                    {{price(totalBayar)}} 
                </td>
            </tr>
            <tr>
                <td>
                    Total Menang
                </td>
                <td>
                    {{price(totalMenang)}} 
                </td>
            </tr>
            <tr>
                <td>
                    Total
                </td>
                <td>
                    {{price(total)}} 
                </td>
            </tr>
        </div>
        <ButtonCustom
            :loading="loading"
            type="green" size="sm" class="r-mlr-10"
            @click.prevent="sendData()"
        >Submit</ButtonCustom>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import tableMethods from '@/mixins/tableMethods'
import common from '@/mixins/function/common'
import { price } from '@/common/currency'
import tableMixins from '@/mixins/tableMixins'
export default {
    mixins: [
        common, 
        tableMixins,
        tableMethods
    ],
    components: {
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            request: 'periode/getRequest',
            item: 'periode/getItem',
        }),
    },
    data: () => ({
        showThead: false,
        data: {
            last_page: 0,
            data: [],
            total: 0,
        },
        totalBayar: 0,
        totalMenang: 0,
        totalBeli: 0,
        total: 0,
        modal: null,
    }),
    methods: {
        ...mapActions ({
            resetItem: 'periode/resetItem',
            resetRequest: 'periode/resetRequest',
            setItem: 'periode/setItem',
            getAllAnalisa: 'periode/getAllAnalisaNomor',
            store: 'periode/store',
            update: 'periode/update',
        }),
        resetData() {
            this.modal = null
            this.totalBayar = 0
            this.totalMenang = 0
            this.totalBeli = 0
            this.total = 0
            this.resetItem()
            this.resetRequest()
        },
        price(value){
            return price(value)
        },
        async getData() {
            this.fetchData = true
            this.item.nomor = this.$route.params.nomor;
            this.item.id = this.$route.params.id;
            await this.getAllAnalisa(this.item)
            this.fetchData = false
            const response = this.getResponse('periode')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            } else {
                this.data = this.$store.getters[`periode/getData`]
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Periode',
                link: {name: 'ViewSettingPeriode'},
                current: false
            },
            {
                name: 'Analisa Nomor',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Setting Periode')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
            this.getAllTotal()
        },
        async sendData() {
            this.loading = true
            await this.store({data: this.data, id: this.$route.params.id})
            this.loading = false
            const response = this.getResponse('periode')
            this.showSnackbar({
                type: response.status == 1 ? 'success' : 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
            this.$router.push({ name: 'ViewSettingPeriode'})
        },
        async updateData(item) {
            this.loading = true
            await this.update(item)
            this.loading = false
            const response = this.getResponse('periode')
            this.showSnackbar({
                type: response.status == 1 ? 'success' : 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
        },
        getAllTotal() {
            this.totalBayar = 0
            this.totalMenang = 0
            this.totalBeli = 0
            this.total = 0
            this.data.forEach(result => {
                this.totalBeli += result.beli
                this.totalBayar += result.bayar
                this.totalMenang += result.menang
                this.total = this.totalMenang - this.totalBeli + this.totalBayar
            });
        }
    }
}
</script>