<template>
    <div class="r-table-pagination">
        <div class="r-pagination r-center-flex r-isWrap">
        <div class="r-mr-auto r-center-flex r-mt-10">
            <span>Data per halaman: </span> 
            <div class="r-autocomplete2"  @click.prevent="pageDropdown = !pageDropdown">
                <p class="r-inputStyle r-ptb-4 r-plr-12 r-mlr-10">{{request.show}}</p>
                <div class="r-autocomplete2-place" v-if="pageDropdown" @mouseleave="pageDropdown = false">
                    <div class="r-autocomplete2-items">
                        <div class="r-autocomplete2-item" v-for="(item, index) in pageShow" :key="`paginationshow-${index}`" @click.prevent="setShow(item)" >{{item}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="r-ml-auto r-center-flex r-mt-10">
            <span>{{data.from || 0}} - {{data.to || 0}} dari {{data.total || 0}}</span>
            <div class="r-pagination-movebutton r-center-flex">
                <input type="image" :src="require('@/assets/icons/others/arrow-left.svg')" :disabled="request.page < 2" @click.prevent="getData(-1)">
                <input type="image" style="transform: rotate(180deg)" :src="require('@/assets/icons/others/arrow-left.svg')" :disabled="request.page >= data.last_page" @click.prevent="getData(1)">
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        request: {
            require: true
        },
        data: {
            require: false
        }
    },
    data: () => ({
        pageDropdown: false,
        pageShow: [10,25,50,100]
    }),
    methods: {
        setShow(value){
            this.$emit("setShow", value)
        },
        getData(value){
            this.$emit("getData", value)
        },
    }
}
</script>