import TableCustom from '@/components/Helper/Table/Table'

export default {
  components: {
    TableCustom
  },
  data: () => ({
    fetchData: false,
    popup: null
  }),
  methods: {
    curNumber(index){
      return index + (this.request.show * (this.request.page - 1)) + 1
    },
    setTime() {
      this.fetchData = true
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(this.getData, 1000);
    },
    clearTime() {
      clearTimeout(this.typingTimer);
    },
    togglePopup(value){
      this.popup = this.popup == value ? null : value
    },
    setSortBy(value){
      if(value == this.request.sortBy){
          if(this.request.sorting == 'DESC'){
              this.request.sorting = 'ASC'
          }else{
              this.request.sorting = 'DESC'
          }
      }else{
          this.request.sorting = 'DESC'
      }
      this.request.sortBy = value
      this.getData()
    },
    setShow(value){
      this.request.show = value
      this.getData()
    }
  }
}